const Article = () => {
    return {
        "display_type": "ARTICLE",
        "title": "",
        "picture": {
            img: ''
        },
        "stats": {
            comments: "0",
            shares: "0"
        },
        "iso_date": "2017-01-01T00:00:00+02:00",
        "date": "01 stycznia 2017 0:00",
        "category": {},
        "author": {}
    };
}

export const BridTileArticle = () => {
    return {
        "display_type": "ARTICLE",
        "title": "",
        "link": "",
        "picture": {
            img: ''
        },
        "stats": {
            comments: "0",
            shares: "0"
        },
        "articleID": "",
        "time": "",
        "req_amount": "",
        "iso_date": "2017-01-01T00:00:00+02:00",
        "date": "01 stycznia 2017 0:00",
        "category": {},
        "author": {},
    };
}

export const TimerArticle = () => {
    return {
        "display_type": "ARTICLE",
        "title": "",
        "title_top": "",
        "time": "",
        "iso_date": "2017-01-01T00:00:00+02:00",
        "date": "01 stycznia 2017 0:00",
        "category": {},
        "author": {}
    };
}

export const BridArticle = () => {
    return {
        "display_type": "ARTICLE",
        "title": "",
        "link": "",
        "title_button1": "",
        "article1_id": "",
        "title_button2": "",
        "article2_id": "",
        "title_button3": "",
        "article3_id": "",
        "time": "",
        "picture": {
            img: ''
        },
        "iso_date": "2017-01-01T00:00:00+02:00",
        "date": "01 stycznia 2017 0:00",
    };
}

export const HardcoverArticle = () => {
    return {
        "display_type": "HARDCOVER",
        "tracking_code": "",
        "iso_date": "2017-01-01T00:00:00+02:00",
        "date": "01 stycznia 2017 0:00",
    }
}

export default Article;