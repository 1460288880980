import React from "react";

export default class Search extends React.Component {

  render() {
    return (
      <div className="form-group">
        <label className="col-sm-3 control-label">Szukaj artykułu</label>
        <div className="col-sm-9">
          <input
            ref={input => (this.searchInput = input)}
            type="text"
            name="search"
            className="form-control"
            placeholder=""
          />
        </div>
      </div>
    );
  }
}
