export default function(elts) {
  for (var i = 0; i < elts.length; i++) {
    var html = elts[i].innerHTML;

    html = html.replace("[br]", "<br />");
    html = html.replace("[b]", "<strong>");
    html = html.replace("[/b]", "</strong>");

    elts[i].innerHTML = html;
  }
}
