import React from 'react';

const AutofillEditor = props => {
    return (
        <div>
            {props.children}
            <hr />
            <div className="form-group">
                <label className="col-sm-3 control-label">ID kategorii</label>
                <div className="col-sm-9">
                    <input type="text" name="autofill_category" className="form-control" defaultValue={props.settings.autofill_category} placeholder="" />
                </div>
            </div>
        </div>
    );
}

export default AutofillEditor