import React from "react";

import Editor from './EditorArticle'
import AutofillEditor from "./AutofillEditor"
import EditorWrapper from '../../EditorWrapper'

import PhotoOnLeft from "../../../redesign/components/PhotoOnLeft";
import EmptyArticle from "../../common/EmptyArticle";

const BPhotoOnLeft = props => {
  var article = EmptyArticle();
  const settings = props.settings;
  const imageUrl = (settings['background-image'] ? settings['background-image'] : '');

  article.title = props.autofill ? `Automatyczne wypełnienie: ${settings.autofill_category || 'podaj kategorię'}` : settings.title_normal;
  article.picture.img = (!!imageUrl ? window.MEDIA_DOMAIN + '/' + imageUrl.replace('.', ',780,0,0,0.') : '');
  article.url = settings.link;
  article.partner = settings.partner;
  article.label = settings.label;
  article.category = { name: settings.category };
  article.player = (parseInt(settings.player) === 1);
  article.iconHotNews = (parseInt(settings.iconHotNews) === 1);
  article.stats = (settings.stats ? settings.stats : { shares: '0', comments: '0' });
  article.background = settings.background;
  // props.onRegisterBox(props.blockId, props.id, 'title_normal')

  // article.hasMobilePcs = props.blockSettings.pcs_background_mobile;
  // article.hasDesktopPcs = props.blockSettings.pcs_background_desktop;

  if (settings.embed) {
    article.embed = settings.embed
  }

  return (
    <div className={props.classes + ' pos-relative'} {...props.events}>
      <EditorWrapper {...props} editor={Editor} />
      <PhotoOnLeft
        article={article}
        boxSize="xs"
        imageHeight={props.imageHeight}
        media={settings.media}
        isDarkMode={props.isDarkMode}
        style={settings.background || props.style}
      />
    </div>
  );
};

export default BPhotoOnLeft;
