import React from "react";

const Link = props => {
  return (
    <div className="form-group">
      <label className="col-sm-3 control-label">Link</label>
      <div className="col-sm-9">
        <input
          type="text"
          name="link"
          className="form-control"
          defaultValue={props.value}
          placeholder=""
        />
      </div>
    </div>
  );
};

export default Link;
