import React from "react";
import { observer } from "mobx-react";

import Markiter from "./markup";

import BTextOnPicture from "./box/BTextOnPicture";
import BPhotoOnTop from "./box/BPhotoOnTop";
import BPhotoOnLeft from "./box/BPhotoOnLeft";


//Lazy load
const boxTypes = {
  //Boxy     
  "news": React.lazy(() => import(/* webpackChunkName: "box__News" */"./box/News")),
  "pile": React.lazy(() => import(/* webpackChunkName: "box__Pile" */"./box/Pile")),
  "partner": React.lazy(() => import(/* webpackChunkName: "box__Partner" */"./box/Partner")),
  "list": React.lazy(() => import(/* webpackChunkName: "box__List" */"./box/List")),
  "title": React.lazy(() => import(/* webpackChunkName: "box__Title" */"./box/Title")),
  "bloger": React.lazy(() => import(/* webpackChunkName: "box__Bloger" */"./box/Bloger")),
  "tetragon": React.lazy(() => import(/* webpackChunkName: "box__TrudatTetragon" */"./box/TrudatTetragon")),
  "trudat-popular": React.lazy(() => import(/* webpackChunkName: "box__TrudatPopular" */"./box/TrudatPopular")),

  //Redesign A (05/2019)
  "APhotoOnLeft": React.lazy(() => import(/* webpackChunkName: "box__APhotoOnLeft" */"./box/APhotoOnLeft")),
  "APhotoOnTop": React.lazy(() => import(/* webpackChunkName: "box__APhotoOnTop" */"./box/APhotoOnTop")),
  "ATextOnPicture": React.lazy(() => import(/* webpackChunkName: "box__ATextOnPicture" */"./box/ATextOnPicture")),
  "AQuote": React.lazy(() => import(/* webpackChunkName: "box__AQuote" */"./box/AQuote")),
  "AOnNetworkPlayer": React.lazy(() => import(/* webpackChunkName: "box__AOnNetworkPlayer" */"./box/AOnNetworkPlayer")),
  "AImageSlider": React.lazy(() => import(/* webpackChunkName: "box__AImageSlider" */"./box/AImageSlider")),
  "AColumnists": React.lazy(() => import(/* webpackChunkName: "box__AColumnists" */"./box/AColumnists")),
  "ASpecialDaysCounter": React.lazy(() => import(/* webpackChunkName: "box__ASpecialDaysCounter" */"./box/ASpecialDaysCounter")),
  "ASpecialDaysCounterTimer": React.lazy(() => import(/* webpackChunkName: "box__ASpecialDaysCounterTimer" */"./box/ASpecialDaysCounterTimer")),

  //Redesign B (02/2022)
  "BTextOnPicture": BTextOnPicture,
  "BSeeMoreLink": React.lazy(() => import(/* webpackChunkName: "box__BSeeMoreLink" */"./box/BSeeMoreLink")),
  "BPhotoOnTop": BPhotoOnTop,//React.lazy(()=>import(/* webpackChunkName: "box__BPhotoOnTop" */"./box/BPhotoOnTop")),
  "BPhotoOnTopLifestyle": React.lazy(() => import(/* webpackChunkName: "box__BPhotoOnTopLifestyle" */"./box/BPhotoOnTopLifestyle")),
  "BPhotoOnLeft": BPhotoOnLeft,//React.lazy(()=>import(/* webpackChunkName: "box__BPhotoOnLeft" */("./box/BPhotoOnLeft")),
  "BColumnists": React.lazy(() => import(/* webpackChunkName: "box__BColumnist" */"./box/BColumnists")),
  "BridPrimaryHeader": React.lazy(() => import(/* webpackChunkName: "box__BridPrimaryHeader" */"./box/BridPrimaryHeader")),
  //"BCollectionTile": React.lazy(()=> import(/* webpackChunkName: "box__BCollectionTile" */"./box/BCollectionTile")),
  "BHotNewsBar": React.lazy(() => import(/* webpackChunkName: "box__BHotNewsBar" */"./box/BHotNewsBar")),


  //Brid
  "ABridTile": React.lazy(() => import(/* webpackChunkName: "box__ABridTile" */"./box/ABridTile")),
  "ABridTileSmall1": React.lazy(() => import(/* webpackChunkName: "box__ABridTileSmall1" */"./box/ABridTileSmall1")),
  "ABridTileSmall2": React.lazy(() => import(/* webpackChunkName: "box__ABridTileSmall2" */"./box/ABridTileSmall2")),
  "ABridTileNarrow": React.lazy(() => import(/* webpackChunkName: "box__/ABridTileNarrow" */"./box/ABridTileNarrow")),
  "ABridSideInfo": React.lazy(() => import(/* webpackChunkName: "box__ABridSideInfo" */"./box/ABridSideInfo")),
  "ABridTimer": React.lazy(() => import(/* webpackChunkName: "box__ABridTimer" */"./box/ABridTimer")),
  "ABridCompare": React.lazy(() => import(/* webpackChunkName: "box__ABridCompare" */"./box/ABridCompare")),
  "ABridCompareWithLink": React.lazy(() => import(/* webpackChunkName: "box__ABridCompareWithLink" */"./box/ABridCompareWithLink")),
  "ABridText": React.lazy(() => import(/* webpackChunkName: "box__ABridText" */"./box/ABridText")),

  //Hardcover
  "AHardcoverTile": React.lazy(() => import(/* webpackChunkName: "box__AHardcoverTile" */"./box/AHardcoverTile")),

  // akcje specjalne
  "ASpecialLG": React.lazy(() => import(/* webpackChunkName: "box__SpecialLG" */"./box/ASpecialLG")),
  "AHuawei": React.lazy(() => import(/* webpackChunkName: "box__ASpecialLG" */"./box/ASpecialLG")),
  "ASpecialCarrefour": React.lazy(() => import(/* webpackChunkName: "box__ASpecialCarrefour" */"./box/ASpecialCarrefour")),
  "ASpecialGoodyear": React.lazy(() => import(/* webpackChunkName: "box__ASpecialGoodyear" */"./box/ASpecialGoodyear")),
  "ASpecialSantander": React.lazy(() => import(/* webpackChunkName: "box__ASpecialSantander" */"./box/ASpecialSantander")),
  "AMiniSite": React.lazy(() => import(/* webpackChunkName: "box__AMiniSite" */"./box/AMiniSite")),
  "AMiniSite2": React.lazy(() => import(/* webpackChunkName: "box__AMiniSite2" */"./box/AMiniSite2")),
};

@observer
export default class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    Markiter(document.getElementsByClassName("hp__box__title"));
    Markiter(document.getElementsByClassName("hp__box__tetragon-title"));
    Markiter(document.getElementsByClassName("hp__news-list__element"));
  }

  render() {
    var box = null,
      params = {
        settings: this.props.settings ? this.props.settings : {},
        blockSettings: this.props.blockSettings,
        classes:
          (this.props.classes ? this.props.classes : "") +
          (this.state.hover ? " hp__box--hover" : ""),
        thumbnailHeight: this.props.thumbnailHeight
      };

    box = boxTypes[this.props.type];

    if (box === undefined) {
      return (
        <div data-type={this.props.type}>
          unrecognized box: {this.props.type}
        </div>
      );
    } else {
      const boxComponent = React.createElement(box, { ...this.props, ...params });
      return boxComponent;
    }
  }
}