import React from "react";

export default class FileUpload extends React.Component {
  componentDidUpdate() {
    this.componentDidMount();
  }

  componentDidMount() {}

  handleDelete = e => {};

  render() {
    var label = this.props.label ? this.props.label : "Obrazek";
    return (
      <div className="form-group">
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-9">
          <input
            ref={input => (this.hiddenInput = input)}
            type="hidden"
            className="file-input-hidden"
          />
          <input
            ref={input => (this.fileInput = input)}
            id="mphoto-upload"
            type="file"
            name="mphoto"
            className="form-control"
          />
          <div ref={input => (this.formStatus = input)} />
          <span ref={span => (this.filenameSpan = span)}>
            {this.props.value}
          </span>
          <button
            className="btn btn-link"
            type="button"
            onClick={this.handleDelete}
          >
            usuń
          </button>
        </div>
      </div>
    );
  }
}
