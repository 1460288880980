import React from 'react';

import FileUpload from '../editor/FileUpload'
import Search from '../editor/Search'
import Link from '../editor/Link'

const EditorArticle = props => {
	return (
		<div>
			{props.children}
			<Search />
			<hr />
			<Link value={props.settings.link} />
			<div className="form-group">
				<label className="col-sm-3 control-label">Tytuł</label>
				<div className="col-sm-9">
					<input type="text" name="title_normal" className="form-control" defaultValue={props.settings.title_normal} placeholder="" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-3 control-label">Partner</label>
				<div className="col-sm-9">
					<input type="text" name="partner" className="form-control" defaultValue={props.settings.partner} placeholder="" />
				</div>
			</div>
			<div className="form-group">
				<label className="col-sm-3 control-label">opcje</label>
				<div className="col-sm-9">
					<div className="checkbox">
						<label className="control-label">
							<input type="hidden" name="player" value="0" />
							<input type="checkbox" name="player" value="1" defaultChecked={props.settings.player == "1"} />
							player
						</label>
					</div>
				</div>
			</div>
			<FileUpload value={props.settings['background-image']} />

		</div>
	);
}


export default EditorArticle;